<template>
  <div>
    <b-row class="match-height d-flex align-items-center">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="filterDetail.keywords"
            placeholder="Kayıt ara.."
            @keydown.enter="filterAction"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="filterAction"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="detailFilter"
          switch
          inline
          @click="detailFilter = !detailFilter"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
      <b-col
        v-if="addBtn"
        md="auto"
      >
        <div class="text-right">
          <b-button
            variant="primary"
            :to="$route.path + '/add'"
          >
            <FeatherIcon icon="PlusIcon" />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="detailFilter">
      <b-col
        class="mt-1"
        md="6"
      >
        <administrative-request-search-dates-card />
      </b-col>
      <b-col
        class="mt-1"
        md="6"
      >
        <administrative-request-search-department-card />
      </b-col>
      <b-col class="mt-1">
        <administrative-request-search-subject-card />
      </b-col>
      <b-col class="mt-1">
        <administrative-request-search-status-card />
      </b-col>
      <b-col class="mt-1">
        <administrative-request-search-status-code-card />
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          :disabled="dataLoading"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          variant="secondary"
          class="ml-1"
          @click="resetFilter"
        >
          <FeatherIcon icon="TrashIcon" />
          Filtreyi Sıfırla
        </b-button>
        <b-button
          v-if="!addBtn"
          variant="danger"
          class="ml-1"
          :href="downloadExcel"
          target="_blank"
          :disabled="dataList.length < 1"
        >
          <FeatherIcon icon="DownloadIcon" />
          Excel Aktar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import AdministrativeRequestSearchDatesCard from '@/views/Administrative/Elements/Search/SearchBarDatesCard.vue'
import AdministrativeRequestSearchSubjectCard from '@/views/Administrative/Elements/Search/SubjectCard.vue'
import AdministrativeRequestSearchStatusCard from '@/views/Administrative/Elements/Search/StatusCard.vue'
import AdministrativeRequestSearchStatusCodeCard from '@/views/Administrative/Elements/Search/StatusCodeCard.vue'
import AdministrativeRequestSearchDepartmentCard from '@/views/Administrative/Elements/Search/DepartmentCard.vue'

export default {
  name: 'AdministrativeRequestSearchBar',
  components: {
    AdministrativeRequestSearchDepartmentCard,
    AdministrativeRequestSearchStatusCodeCard,
    AdministrativeRequestSearchStatusCard,
    AdministrativeRequestSearchSubjectCard,
    AdministrativeRequestSearchDatesCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
    addBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailFilter: false,
    }
  },
  computed: {
    filterDetail() {
      return this.$store.getters['administrativeRequest/filterData']
    },
    dataLoading() {
      return this.$store.getters['administrativeRequest/dataLoading']
    },
    dataList() {
      return this.$store.getters['administrativeRequest/dataList']
    },
    downloadExcel() {
      const urlParams = []
      if (this.filterDetail.keywords) {
        urlParams.push(`keywords=${this.filterDetail.keywords}`)
      }
      if (this.filterDetail.start_date) {
        urlParams.push(`start_date=${this.filterDetail.start_date}`)
      }
      if (this.filterDetail.finish_date) {
        urlParams.push(`finish_date=${this.filterDetail.finish_date}`)
      }
      if (this.filterDetail.id_com_department) {
        urlParams.push(`id_com_department=${this.filterDetail.id_com_department}`)
      }
      if (this.filterDetail.id_com_administrative_request_subject) {
        urlParams.push(`id_com_administrative_request_subject=${this.filterDetail.id_com_administrative_request_subject}`)
      }
      if (this.filterDetail.id_com_administrative_request_status) {
        urlParams.push(`id_com_administrative_request_status=${this.filterDetail.id_com_administrative_request_status}`)
      }
      if (this.filterDetail.id_com_administrative_request_status_code) {
        urlParams.push(`id_com_administrative_request_status_code=${this.filterDetail.id_com_administrative_request_status_code}`)
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/data/administrative_request/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  methods: {
    resetFilter() {
      this.$store.dispatch('administrativeRequest/resetFilterData')
    },
  },
}
</script>
