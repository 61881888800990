<template>
  <div>
    <b-form-group
      label="Başlangıç-Bitiş Tarihleri"
      label-for="start_date"
    >
      <b-input-group id="start_date">
        <b-form-datepicker
          v-model="dataItem.start_date"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
          locale="tr"
          start-weekday="1"
        />
        <b-input-group-append is-text>
          -
        </b-input-group-append>
        <b-form-datepicker
          v-model="dataItem.finish_date"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin',dateFormatOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }}"
          locale="tr"
          start-weekday="1"
        />
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormDatepicker, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'AdministrativeRequestSearchDatesCard',
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    dataItem() {
      return this.$store.getters['administrativeRequest/filterData']
    },
  },
}
</script>
<style scoped></style>
