<template>
  <b-form-group
    label="Öncelik"
    label-for="id_com_administrative_request_status_code"
  >
    <v-select
      v-model="dataItem.id_com_administrative_request_status_code"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Giriniz"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'AdministrativeRequestSearchStatusCodeCard',
  components: {
    BFormGroup,
    vSelect,
  },

  computed: {
    dataList() {
      return this.$store.getters['administrativeRequestStatusCode/dataList']
    },
    dataItem() {
      return this.$store.getters['administrativeRequest/filterData']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('administrativeRequestStatusCode/getDataList', {
        select: [
          'com_administrative_request_status_code.id AS id',
          'com_administrative_request_status_code.title AS title',
        ],
      })
    },
  },
}
</script>
